import { useCallback, useEffect, useRef, useState } from "react";
import ApiHelper from "../util/ApiHelper";

function useFetch(supplier, query, category, status, page) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [list, setList] = useState([]);
  const [hasNoMore, setHasNoMore] = useState(false);
  const prevQuery = useRef('')

  const sendQuery = useCallback(async () => {
    try {
      await setLoading(true);
      await setError(false);
      const sort = (status === 'publish') ? 'published_at' : '_id'
      let res = await ApiHelper.getSupplierProducts({ supplier, query, category, status, page, sort })
      setHasNoMore(res.length > 0)
      await setList(prevList => {
        return [...prevList, ...res]
      });
      setLoading(false);
    } catch (err) {
      console.dir(err)
      setError(err);
    }
  }, [supplier, query, category, status, page]);

  useEffect(() => {
    setList([])
  }, [query, category, status])

  useEffect(() => {
    sendQuery()
  }, [query, category, status, page]);

  return { loading, error, list, hasNoMore };
}

export default useFetch;
