import './ProductApp.scss';
import ApiHelper from './util/ApiHelper';
import { useState, useEffect } from 'react';
import { ProductDetails } from './components/ProductDetails';
import { useParams } from 'react-router-dom';

function ProductApp() {
  const { supplier, code } = useParams();
  const [product, setProduct] = useState({})

  const loadProduct = () => {
    ApiHelper.getProductDetails(supplier, code)
      .then((data) => {
        setProduct(data)
      })
  }

  useEffect(()=>{
    loadProduct()
  }, [])

  return (
    <div className="App">
      <div className='page-content'>
        {product.code && <ProductDetails data={product} supplier={supplier} onUpdate={loadProduct} />}
      </div>
    </div>
  );
}

export default ProductApp;
