function objectToArray(object, key="key", value="value"){
    const objArray = [];
    Object.keys(object).forEach(k => {
        let elem = {}
        elem[key] = k
        elem[value] = object[k]
        objArray.push(elem)
    });
    return objArray
}

module.exports = {objectToArray}