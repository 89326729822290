export const config = {
    baseLanguages: {
        'nl': ['nl', 'be'],
        'de': ['de', 'at', 'ch', 'lu_de'],
        'en': ['en', 'dk'],
        'fr': ['fr', 'be_fr', 'lu_fr'],
        'it': ['it'],
        'es': ['es'],
    },
    languages: {
        'nl': 'Dutch',
        'be': 'Belgium',
        'fr': 'France',
        'be_fr': 'Belgium/French',
        'de': 'German',
        'en': 'English',
        'ch': 'Swedish',
        'it': 'Italy',
        'es': 'Spain',
        'at': 'Austria',
        'lu_de': 'Luxembourg/German',
        'lu_fr': 'Luxembourg/French',
        'dk': 'Denmark',
    },
    productStatusOptions: [
        {
            value: 'publish', 
            label: 'Publish', 
        },
        {
            value: 'managed', 
            label: 'Managed', 
        },
        {
            value: 'notmanaged', 
            label: 'Not Managed', 
        }
    ]
}