import React, { useState } from 'react';
import { Checkbox, Button } from 'antd';
import '../App.scss'; 

export const CheckButtonGroup = (props) => {
  const [sizes, setSizes] = useState(props.sizes);
  
  const onChange = (checkedValue) => {
    let updatedValues = [...sizes];
    if (updatedValues.includes(checkedValue)) {
      updatedValues = updatedValues.filter((val) => val !== checkedValue);
    } else {
      updatedValues.push(checkedValue);
    }
    setSizes(updatedValues);
    props.onSelectSize(updatedValues);
  };
  
  return (
    <div>
      <h3>Sizes</h3>
      {props.availableSizes.map((option) => (
        <Button
          key={option}
          type={sizes.includes(option) ? 'primary' : 'default'}
          onClick={() => onChange(option)}
          style={{ margin: '5px' }}
        >
          <Checkbox
            checked={sizes.includes(option)}
            style={{ display: 'none' }}
          />
          {option}
        </Button>
      ))}
    </div>
  );
};

