import React from 'react';
import { Spin } from 'antd';

const Loader = () => {
    return (
        <div style={styles.loaderContainer}>
            <Spin tip="Loading..." size="large" />
        </div>
    );
};

const styles = {
    loaderContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional for dimming effect
        zIndex: 9999, // Ensure it overlays all content
    },
};

export default Loader;
