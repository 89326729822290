import CardStyles from '../components/Card.module.scss'

export const CardSkeleton = ({count}) => {
    return (
        Array(count).fill().map((item, index) => {
            return (
                <div key={index} className={`${CardStyles.Card} ${CardStyles.skeleton}`}>
                    <div className={CardStyles.image} ></div>
                    <div className={CardStyles.content}>
                        <div className={`name ${CardStyles.text}`}></div>
                        <div className={`price ${CardStyles.text}`}></div>
                        <div className={`colors ${CardStyles.text}`}></div>
                        <div className={`itemnumber ${CardStyles.text}`}></div>
                    </div>
                </div>
            )
        })
    )
}