import { useState, useTransition, useEffect } from "react";
import { FixedSizeList as List } from "react-window"
import { config } from "../config";
import { SearchBox } from "./SearchBox";
// import { SelectSourceItem } from "./SelectSourceItem"
// import { SelectTargetItem } from "./SelectTargetItem"
import { message,Popover } from 'antd';
import { AddNewSpec } from "./AddNewSpec";
import { escapeRegExp } from 'lodash'
import { Swatch } from "./Swatch"
import { Button } from "./Button"
import { ButtonGroup } from "./ButtonGroup"
import Styles from './SelectList.module.scss'
import ApiHelper from "../util/ApiHelper.js";

const SelectSourceItem = (props) => {
    return (
        <div style={props.style} className={`${Styles.List__item}${props.isSelected ? ` ${Styles['List__item--selected']}` : ''}${props.mapping ? ` ${Styles['List__item--mapped']}` : ''}`} data-id={props.item.id} onClick={(event, id) => props.handleItemClick(event, props.index)} >
            <div className={Styles.List__item__head}>
                {props.item.hexColor && <div className={Styles.List__item__icon}><Swatch color={props.item.hexColor} size="1rem" /></div>}
                <div className={Styles.List__item__name}>
                    {props.item.title.nl}
                    {props.mapping && <small>{props.mapping.title.nl}</small>}
                </div>
            </div>
        </div>
    )
}

const SelectTargetItem = (props) => {
    const onCancel = (event) => {
        //Do something on cancel
    }
    return (
        <div style={props.style} className={`${Styles.List__item}${props.isSelected ? ` ${Styles['List__item--selected']}` : ''}${props.mapping ? ` ${Styles['List__item--mapped']}` : ''}`} data-id={props.item.id} onClick={(event, id) => props.handleItemClick(event, props.index)} >
            <div className={Styles.List__item__head}>
                {props.item.hexColor && <div className={Styles.List__item__icon}><Swatch color={props.item.hexColor} size="1rem" /></div>}
                <div className={Styles.List__item__name}>
                    {props.item.title.nl}
                    {props.mapping && props.mapping.length > 0 && <small>{props.mapping[0].title.nl} {props.mapping.length > 1 ? `(+${props.mapping.length - 1})` : ''}</small>}
                </div>
                {props.enableTargetMapping && props.isSelected && <ButtonGroup className="list__item__actions">
                    <Button handleClick={props.onApply}>Apply</Button>
                    <Button handleClick={onCancel}>Cancel</Button>
                </ButtonGroup>}
            </div>
        </div>
    )
}

export const SelectList = (props) => {
    const [searchQuery, setSearchQuery] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [isPending, startTransition] = useTransition()
    const [isAddNewSpecPopoverVisible, setIsAddNewSpecPopoverVisible] = useState(false)
    const [items, setItems] = useState(props.items);

    useEffect(() => {
        setItems(props.items);
    }, [props.items]);

    const handleSearch = (event) => {
        setSearchValue(event.target.value);
        startTransition(() => setSearchQuery(event.target.value))
    }

    const handleItemClick = (event, index) => {
        //Selects click element, or unselects when same element clicked twice
        let selectedItem = (props.selectedItem && visibleItems[index].id === props.selectedItem.id) ? null : visibleItems[index]
        props.selectionChanged(event, selectedItem)
    }

    const handleAddNewSave = (data) => {
        let specification = data.target.specification_type.value;
        const fields = data.target.getElementsByClassName('specname');
        let specValueTexts = Array.from(fields).reduce((obj, field) => {
            const lang = field.name.match(/specname\[(.*)\]/)[1];
            obj[lang] = field.value;
            return obj;
        }, {});
        ApiHelper.addCMSSpecValue(specification,{value_translations:specValueTexts})
        .then((res) => {
            if (res) {
                message.success(res.message)
                setIsAddNewSpecPopoverVisible(false)
                ApiHelper.getCMSSpecs(specification)  // Fetch updated list from API
                    .then( (updatedItems) => {
                        setItems(updatedItems)
                        props.onChangeTargetList(updatedItems)
                    })
                    .catch(error => message.error("Failed to fetch updated items."));
            }
            else {
                message.error(res.message)
            }
        })
        .catch((error) => {
            message.error("An error occurred while saving the specification.");
        });
    }

    const handleAddNewCancel = (event) => {
        setIsAddNewSpecPopoverVisible(false);
    }

    const visibleItems = items.filter((item) => {
        const regex = new RegExp(escapeRegExp(searchQuery), "i")
        return regex.test(item.title.nl) || regex.test(item.id) || regex.test(item.title.en)
    })

    useEffect(() => {
        if (visibleItems.length == 1) {
            props.selectionChanged({}, visibleItems[0])
        }
    }, [])

    const renderChild = (item, index, mapping, style) => {
        if (props.isTarget) {
            return (
                <SelectTargetItem
                    style={style}
                    key={item.id}
                    index={index}
                    item={item}
                    handleItemClick={handleItemClick}
                    isSelected={props.selectedItem && props.selectedItem.id === item.id}
                    enableTargetMapping={props.enableTargetMapping}
                    onApply={props.onApply}
                    mapping={mapping}
                />
            )
        }
        else {
            return (
                <SelectSourceItem
                    style={style}
                    key={item.id}
                    index={index}
                    mapping={mapping}
                    item={item}
                    handleItemClick={handleItemClick}
                    isSelected={props.selectedItem && props.selectedItem.id === item.id}
                />
            )
        }
    }

    return (
        <div id={`SelectList-${props.id}`} className={`${Styles.Component}`}>
            <h3>{props.title}</h3>
            {props.isSearchable && <SearchBox id={props.id} searchValue={searchValue} handleChange={handleSearch} isLoading={isPending} />}
            <div id={`SelectSourceList-${props.id}`} className={Styles.Component__list}>
                <List
                    height={Math.min(visibleItems.length ? props.listHeight : 0, visibleItems.length * 33)}
                    width={400}
                    itemCount={visibleItems.length}
                    overscanCount={5}
                    itemSize={33}  >
                    {({ index, style }) => {
                        let item = visibleItems[index]
                        let mapping = null;
                        if (props.mappings && props.mappings[item.id]) {
                            mapping = props.mappings[item.id]
                        }

                        return renderChild(item, index, mapping, style)
                    }}
                </List>
                {props.isTarget && props.selectedSourceItem &&
                    <Popover
                        content={<AddNewSpec specificationType={props.specificationType} languages={config.languages} values={props.selectedSourceItem.title} onSave={handleAddNewSave} onCancel={handleAddNewCancel} />}
                        title="Add new Specification"
                        trigger="click"
                        visible={isAddNewSpecPopoverVisible}
                        onVisibleChange={setIsAddNewSpecPopoverVisible}
                    >
                        <button>Add new</button>
                    </Popover>}
            </div>
        </div>
    )
} 