import styles from './Swatch.module.scss'

export const Swatch = ({ color, colorName = null, size = "1rem", dropStyle = false }) => {
    const formatColor = (color) => {
        if (Array.isArray(color) && color.length > 1) {
            return `linear-gradient(135deg, #${color[0]} 50%, #${color[1]} 50%)`
        }
        else if (color.indexOf('-') > -1) {
            color = color.split('-')
            return `linear-gradient(135deg, #${color[0]} 50%, #${color[1]} 50%)`
        }
        else {
            return `#${color}`;
        }
    }

    const swatchStyle = {
        width: size,
        height: size,
        background: colorName || formatColor(color)
    }
    
    return (
        <div className={`${styles.swatch} ${dropStyle?styles.drop:''}`} style={swatchStyle}></div>
    )
}