import { Button, Checkbox, Form, Input } from 'antd';
import styles from './LoginForm.module.scss'
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { login } from '../../util/AuthHelper';
import { useLocation, useNavigate } from 'react-router-dom';

export const LoginForm = () => {
    const auth = useAuth()

    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || "/"

    const onFinish = (values) => {
        login(values.username, values.password)
            .then(res => {
                auth.updateAuth(res)
                navigate(from, { replace: true })
            })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className={styles.wrapper}>
            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your username!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your password!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

// LoginForm.propTypes = {
//     setUser: PropTypes.func.isRequired
// }