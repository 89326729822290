import { useState } from "react"
import { useEffect } from "react"
import ApiHelper from "../util/ApiHelper"
import styles from './SupplierList.module.scss'

export const SupplierList = (props) => {
    const [suppliers, setSuppliers] = useState([])

    useEffect(() => {
        ApiHelper.getSuppliers()
            .then((data) => {
                setSuppliers(data)
            })
    }, [])

    return(
        <ul className={styles.container}>
           {suppliers.map((supplier) => {
            return <li key={supplier}>
                <a href={`/supplier/${supplier}`}>{supplier}</a>
            </li>
           })}
        </ul>
    )
}