import { useEffect, useState } from "react"
import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useAuth } from "../hooks/useAuth"
import { getCurrentUser } from "../util/AuthHelper"

export const RequireAuth = () => {
    const auth = useAuth()
    const location = useLocation()

    useEffect(() => {
        if(!auth?.user){
            auth.updateAuth(getCurrentUser())
        }
    }, [])

    return (
        auth?.user
            ? <Outlet />
            : <Navigate to="/login" state={{from: location}} replace />
    )
}