import React, { useState } from "react";
import { getCurrentUser } from "../util/AuthHelper";

const AuthContext = React.createContext({});

export const AuthProvider = ({ children }) => {
    const updateAuth = (val) => {
        setAuth({
            user: val,
            updateAuth
        })
    }

    const [auth, setAuth] = useState({
        user: getCurrentUser() || null,
        updateAuth
    });

    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;