export const AddNewSpec = (props) => {

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target)
        const data = Object.fromEntries(formData.entries())
        props.onSave(event, data)
    }

    return (
        <form name="addnewspec" onSubmit={handleSubmit}>
            <input type={`hidden`} name={`specification_type`} defaultValue={props.specificationType} required/>
            {Object.keys(props.languages).map(lang => {
                return (
                    <div key={lang}>
                        <label>
                            {lang} <input name={`specname[${lang}]`} className={`specname`} defaultValue={props.values[lang]} required/>
                        </label>
                    </div>
                )
            })}
            <div>
                <button className="small">Save</button>
                <button className="small" onClick={(e) => { e.preventDefault(); props.onCancel(e) }}>Cancel</button>
            </div>
        </form>
    )
}