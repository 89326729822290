import { Layout as LayoutTemplate, Menu } from 'antd';
import { Outlet, useNavigate, useParams, useResolvedPath } from 'react-router-dom';
const { Header, Content } = LayoutTemplate;

export const Layout = () => {
  const { supplier } = useParams()
  const navigate = useNavigate()
  const { pathname } = useResolvedPath()

  const handleMenuClick = (e) => {
    navigate(e.key)
  };

  const items = [
    {
      label: 'Home',
      key: '/'
    },
    {
      label: 'Mappings',
      disabled: supplier ? false : true,
      children: [
        {
          key: `/supplier/${supplier}/mappings/printing`,
          label: `Printing`,
        },
        {
          key: `/supplier/${supplier}/mappings/color`,
          label: `Color`
        }
      ],
    },
    {
      label: 'Margins',
      disabled: supplier ? false : true,
      key: `/supplier/${supplier}/margins`,
    },
    {
      label: 'Logout',
      key: '/logout'
    }
  ]

  return (
    <LayoutTemplate>
      <Header style={{
        display: 'flex'
      }}>
        <div className="logo" />
        <Menu
          theme="dark"
          onClick={handleMenuClick}
          defaultOpenKeys={['sub1']}
          mode="horizontal"
          selectedKeys={[pathname]}
          items={items}
        />
        {!!supplier &&  (
          <div className="supplier-logo">
            <img src={`/supplierlogo/${supplier}.svg`} alt={supplier} />
          </div>
        )}
        {/* <Navbar items={items} /> */}
      </Header>
      <Content style={{ backgroundColor: 'white' }} >
        <Outlet />
      </Content>
    </LayoutTemplate>
  );
}