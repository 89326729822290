import { useEffect, useState, useRef } from 'react';
import Mapper from './components/Mapper';
import ApiHelper from './util/ApiHelper';
import { useParams } from 'react-router-dom';
import { Affix, message } from 'antd';

function MappingApp() {
  const { supplier, specification } = useParams()
  const [sourceItems, SetSourceItems] = useState([])
  const [targetItems, SetTargetItems] = useState([])
  const [mappings, SetMappings] = useState({})
  const mapperRef = useRef()

  const saveChanges = (event) => {    
    const newMappings = mapperRef.current.getChangedMappingsRef()
    ApiHelper.saveSpecificationMappings(supplier, specification, newMappings)
      .then((res) => {
        if(res){
          message.success('Mappings updated successfully')
          SetMappings(res)
        }
        else{
          message.error('Unable to update mappings')
        }
      })
  }

  useEffect(()=>{
    ApiHelper.getCMSSpecs(specification)
      .then((result) => {
        SetTargetItems(result)
      })

    ApiHelper.getSupplierSpecs(supplier, specification)
      .then((result) => {
        SetSourceItems(result)
      })

    ApiHelper.getSpecificationMappings(supplier, specification)
      .then((result) => {
        SetMappings(result)
      })

  }, [supplier, specification])

  return (
    <div className="App">
      <div className='page-content'>
        <Mapper ref={mapperRef} supplier={supplier} specificationType={specification} sourceItems={sourceItems} targetItems={targetItems} mappings={mappings} listHeight={600} onSaveChanges={saveChanges}/>
        <Affix style={{ position: 'absolute', bottom: 0, right: 40 }}>
            <div className="status--actions">
                <button onClick={(event) => saveChanges(event)}>Save Changes</button>
            </div>
        </Affix>
      </div>
    </div>
  );
}

export default MappingApp;
