import { CheckOutlined, CloseOutlined, EditOutlined, InfoOutlined } from '@ant-design/icons';
import { Button, Input, Popover } from 'antd';
import React, { useRef, useState } from 'react';
//import styles from './InplaceEditable.module.scss'

const InlineEdit = ({ value, onChange, original = null, multiline = false }) => {
  const [isEditing, setIsEditing] = useState(false);
  const editedValue = useRef(value)

  // const onKeyDown = (event) => {
  //   if (event.ctrlKey && event.key === "Enter") {
  //     onChange(editedValue.current)
  //     event.target.blur();
  //   }
  //   else if (event.key === 'Escape') {
  //     editedValue.current = value
  //     event.target.blur();
  //   }
  // };


  const handleCancel = () => {
    editedValue.current = value
    setIsEditing(false)
  }

  const handleSave = () => {
    onChange(editedValue.current)
    // setIsEditing(false)
  }

  const handleEdit = () => {
    setIsEditing(true)
  }

  //const onChange = (event) => setEditingValue(event.target.value);
  if (!isEditing) {
    return (
      <div
        onDoubleClick={handleEdit}
        style={{
          display: "flex",
          justifyContent: "space-between",
          whiteSpace: multiline ? "pre-wrap" : 'initial',
          border: "1px dashed gray",
          padding: "5px",
          marginBottom: "10px"
        }}
      >
        {editedValue.current}
        <div className="actions">
          <EditOutlined onClick={handleEdit} />
          {!!original && <Popover content={original} title="Supplier Text" trigger="hover">
            <Button shape="circle" size="small" icon={<InfoOutlined />} />
          </Popover>}
        </div>
      </div>
    )
  }

  if (multiline) {
    return (
      <Input.Group compact style={{ display: "flex" }}>
        <Input.TextArea
          type="text"
          aria-label="Field name"
          defaultValue={editedValue.current}
          onChange={(e) => {
            editedValue.current = e.target.value
            handleSave()
          }}
          //onBlur={() => { setIsEditing(false) }}
          //onKeyDown={onKeyDown}
          autoSize
        //disabled={!isEditing}
        />
        {/* <Button icon={<CheckOutlined />} onClick={handleSave} /> */}
        <Button icon={<CloseOutlined />} onClick={handleCancel} />
      </Input.Group>
    )
  }

  return (
    <Input.Group compact style={{ display: "flex" }}>
      <Input
        type="text"
        aria-label="Field name"
        defaultValue={editedValue.current}
        onChange={(e) => {
          editedValue.current = e.target.value
          handleSave()
        }}
      //onBlur={() => { setIsEditing(false) }}
      //onKeyDown={onKeyDown}
      />
      {/* <Button icon={<CheckOutlined />} onClick={handleSave} /> */}
      <Button icon={<CloseOutlined />} onClick={handleCancel} />
    </Input.Group>
  );
};

export {
  InlineEdit,
  //MultilineEdit
};

/* const App = () => {
  const [value, setValue] = useState("This is inline editable");
  const [multilineValue, setMultilineValue] = useState(
    "This is the multi-line version!"
  );

  return (
    <div id="container">
      <InlineEdit value={value} setValue={setValue} />
      <MultilineEdit value={multilineValue} setValue={setMultilineValue} />
    </div>
  );
}; */

