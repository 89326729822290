import ApiHelper from "./ApiHelper"

export const login = async (username, password) => {
    const user = await ApiHelper.login({username, password})
    if(!user){
        alert('Invalid username or password')
        return false
    }
    
    setCurrentUser(user)
    return user 
}
export const logout = async () => {
    ApiHelper.logout()
        .then(res => {
            if(res){
                removeCurrentUser()
            }
        })
}

export const getCurrentUser = () => {
    const user = localStorage.getItem('user')
    if(!user){
        //todo:MAYBE throw error and logout
        return false
    }
    return JSON.parse(user)
}

export const setCurrentUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user)) 
}

export const removeCurrentUser = () => {
    localStorage.removeItem("user");
}