import { useEffect, useMemo } from 'react'
import Styles from './Card.module.scss'
import { Swatch } from './Swatch'
export const Card = ({ product, supplier }) => {
    const publishedDate = useMemo(() => {
        if(product.status !== 'publish' || !product.published_at ){
            return ''
        }
        return (new Date(product.published_at)).toDateString()
    }, [product.published_at, product.status]) 

    const formateDate = (timestamp) => {
        if(!timestamp){
            return ''
        }
        return (new Date(timestamp)).toDateString()
    }

    useEffect(() => {
        if (!product.startingPrice) {
            console.warn('Starting price not available for product', product.code)
        }
    }, [])
    

    const status = (product?.updated_by_feed) != ''?product?.updated_by_feed:product.status;
    return (
        <a href={`/supplier/${supplier}/product/${product.code}`} className={`${Styles.Card} ${Styles[status] ?? ''} ${(product?.offline && Styles.offline) ?? ''}`}>
            {product.status === 'publish' ? (
                <div className={Styles['date--published']}>{formateDate(product.published_at)}</div>
                ) : (
                <div className={Styles.date}>{formateDate(product.created_at)}</div>                    
            )}
            {product?.internalInfo?.penInkColor && (
                <div className={Styles.inkColor}>
                    <Swatch key={product.internalInfo.penInkColor} colorName={product.internalInfo.penInkColor.toLowerCase()} size="1rem" dropStyle />                    
                </div>
            )}
            <img src={product.mainImage} alt={product.name.nl} style={{ width: '100%' }} />
            <div className={Styles.content} style={{}}>
                <div className={Styles.name}>{product.name.nl}</div>
                <div className={Styles.price}>&euro; {product?.startingPrice?.nl}</div>
                <div className={Styles.colors}>
                    {
                        product.specs.colors.map(color => {
                            return <Swatch key={color} color={color} size="1rem" />
                        })
                    }
                </div>
                <div className={Styles.itemnumber}>{product.code}</div>
            </div>
        </a>
    )
}

