import { Divider } from "antd";
import { useEffect, useId, useRef, useState } from "react";
import styles from './ImageSelectList.module.scss';

export const ImageSelectList = (props) => {
    const [selectedImages, setSelectedImages] = useState([])
    const dragItem = useRef();
    const dragOverItem = useRef();
    const id = 'ImageSelectList' + useId()

    const handleChange = (event, image) => {
        let selections = selectedImages
        if (event.target.checked) {
            selections = [...selections, image]
        }
        else {
            selections = selections.filter(t => t !== image)
        }
        if (props.limit && props.limit > 0) {
            selections = selections.splice(-props.limit)
        }
        setSelectedImages(selections)
        props.onChange(selections)
    }

    const dragStart = (e, position) => {
        dragItem.current = position;
        console.log(e.target.innerHTML);
    }

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
        console.log(e.target.innerHTML);
    }

    const drop = (e) => {
        const copyListItems = [...selectedImages];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setSelectedImages(copyListItems);
        props.onChange(copyListItems)
    }

    useEffect(() => {
        setSelectedImages(props.selectedImages)
    }, [props.selectedImages])


    return (
        <div className={styles.container}>
            <Divider orientation="left">Selected Images</Divider>
            <div className={styles.selectedImages}>
                {selectedImages.map((image, index) => (
                    <div key={image}
                        className={styles.selectedImageItem}
                        onDragStart={(e) => dragStart(e, index)}
                        onDragEnter={(e) => dragEnter(e, index)}
                        onDragEnd={drop}
                        draggable
                    >
                        <img src={image} alt="" style={{ maxWidth: '100px', width: '100%' }} />
                    </div>
                ))}
            </div>
            <Divider orientation="left">Supplier Images</Divider>
            <div className={styles.images}>
                {!props.images.length > 0 ? null : props.images.map((image, index) => {
                    return (
                        <div key={index} className={`${((selectedImages.indexOf(image) > -1) ? styles['selectableImage--selected'] : styles.selectableImage)}`}>
                            <input id={`${id}__${index}`} type="checkbox" checked={selectedImages.indexOf(image) > -1} onChange={e => handleChange(e, image)} />
                            <label htmlFor={`${id}__${index}`} className={`${props.updatedImages[index]?styles['new']:''}`}>
                                <img src={image} alt="" style={{ maxWidth: '200px', width: '100%' }} />
                            </label>
                        </div>
                    )
                })}
            </div>
        </div>
    )
} 