import { Button, message, Transfer } from 'antd';
import { isEmpty, unionBy } from 'lodash';
import { Suspense, useEffect, useReducer, useState } from 'react';
import { Await, defer, useFetcher, useLoaderData } from 'react-router-dom';
import { defaultMargin } from '../../constants/mapping.js';
import ApiHelper from '../../util/ApiHelper.js';
import styles from './MarginFields.module.scss';

const productMarginReducer = (productMargins, action) => {
    switch (action.type) {
        case "set":
            return action.productMargins

        case "add":
            return unionBy(productMargins, action.productIds.map(id => ({
                productId: id,
                margin: action.defaultMargin,
            })), "productId")

        case "remove":
            return productMargins.filter(({ productId }) => productId !== action.productId)

        case "updateMargin":
            return productMargins.map((p) => {
                if (p.productId === action.productId) {
                    return ({
                        productId: p.productId,
                        margin: action.margin
                    })
                } else {
                    return p
                }
            })

        default:
            throw new Error('Undefined action')

    }
}

const MarginFields = () => {
    const { categoryMargin, productMargins = [], products } = useLoaderData()
    const [marginVal, setMarginVal] = useState(categoryMargin)
    const [excludedProducts, updateExcludedProducts] = useReducer(productMarginReducer, productMargins)
    const fetcher = useFetcher()

    //console.log({ actionData })


    useEffect(() => {
        setMarginVal(categoryMargin)
    }, [categoryMargin])

    useEffect(() => {
        updateExcludedProducts({
            type: 'set',
            productMargins
        })
    }, [productMargins])

    const handleChange = (excludeProductIds, dir, moveKeys) => {
        console.log({ excludeProductIds, dir, moveKeys })
        if (dir === "left") {
            updateExcludedProducts({
                type: 'remove',
                productId: moveKeys[0]
            })
        }
        else {
            updateExcludedProducts({
                type: 'add',
                defaultMargin: marginVal,
                productIds: excludeProductIds
            })
        }
    }

    return (
        <div className="">
            <Suspense fallback={<p>Loading ...</p>} >
                <Await
                    resolve={categoryMargin}
                    errorElement={
                        <p>Error loading!</p>
                    }
                >
                    <input type="number" name="margin" value={marginVal} onChange={e => { setMarginVal(e.target.valueAsNumber) }} />
                </Await>
            </Suspense>
            <Transfer
                dataSource={products}
                rowKey={({ id }) => id}
                titles={['All Products', 'Custom Margin']}
                targetKeys={excludedProducts.map(({ productId }) => productId)}
                onChange={handleChange}
                render={({ id, name }) => {
                    const excluded = excludedProducts.find(({ productId }) => id === productId)
                    if (!excluded) {
                        return {
                            value: id,
                            label: name
                        }
                    }

                    return (
                        <div className={styles.excludedProduct}>
                            <span>{name}{name}</span>
                            <input type="number" value={excluded.margin} onChange={(e) => {
                                updateExcludedProducts({
                                    type: 'updateMargin',
                                    productId: id,
                                    margin: e.target.valueAsNumber,
                                })
                            }} />
                        </div>
                    )
                }}
                footer={(props, { direction }) => {
                    if (direction === "right") {
                        return <Button onClick={() => {
                            fetcher.submit({
                                categoryMargin: marginVal,
                                ...excludedProducts.reduce((prev, curr) => ({
                                    ...prev,
                                    [curr.productId]: curr.margin
                                }), {}),
                            }, {
                                method: "POST"
                            })
                        }}>Save Changes</Button>
                    }
                }}
                listStyle={{
                    width: 450,
                }}
                style={{
                    marginTop: 16,
                }}
                oneWay
            />
        </div>
    )
}

MarginFields.Action = async ({ params, request }) => {
    const { supplier, categoryId } = params
    const { categoryMargin, ...productMargins } = Object.fromEntries(await request.formData())
    const result = await ApiHelper.updateCmsCategoryMargin(supplier, categoryId, parseFloat(categoryMargin), Object.entries(productMargins).map(([productId, margin]) => ({
        productId,
        margin
    })))
    if (!isEmpty(result)) {
        message.success('Margins updated successfully')
    }
    else {
        message.error('Unable to save margins')
    }
    return null
}

MarginFields.Loader = async ({ params }) => {
    const { supplier, categoryId } = params
    const { categoryMargin, productMargins } = await ApiHelper.getCmsCategoryMargin(supplier, categoryId)
    const products = await ApiHelper.getSupplierProductsByCategory(supplier, categoryId)
    return defer({ categoryMargin: categoryMargin ?? defaultMargin, productMargins, products })
}

export default MarginFields