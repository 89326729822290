import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../util/AuthHelper";

export const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    logout()
      .then(() => {
        navigate("/login", { replace: true });
      });

  }, []);

  return <></>;
};