import { createBrowserRouter, RouterProvider, useRouteError } from 'react-router-dom';
import './App.scss';
import { Home } from './components/Home';
import { LoginForm } from './components/Login/LoginForm';
import { Logout } from './components/Logout';
import { ProductsGrid } from './components/ProductsGrid';
import { RequireAuth } from './components/RequireAuth';
import CategoryMargins from './components/Settings/CategoryMargins';
import MarginFields from './components/Settings/MarginFields';
import { AuthProvider } from './context/AuthProvider.js';
import { Layout } from './Layout';
import MappingApp from './MappingApp';
import ProductApp from './ProductApp';
import './ProductApp.scss';

function App() {

  return (
    <div className="page-content">
      <AuthProvider>
        <RouterProvider router={createBrowserRouter([
          {
            path: '/',
            element: <Layout />,
            errorElement: <ErrorBoundary />,
            children: [
              {
                path: '/login',
                element: <LoginForm />,
              },
              {
                element: <RequireAuth />,
                children: [
                  {
                    index: true,
                    path: '/',
                    element: <Home />,
                  },
                  {
                    path: '/logout',
                    element: <Logout />,
                  },
                  {
                    path: 'supplier/:supplier',
                    children: [
                      {
                        path: ':filterStatus?',
                        element: <ProductsGrid />
                      },
                      {
                        path: 'product/:code',
                        element: <ProductApp />,
                      },
                      {
                        path: 'mappings/:specification',
                        element: <MappingApp />,
                      },
                      {
                        path: 'margins',
                        loader: CategoryMargins.Loader,
                        element: <CategoryMargins />,
                        children: [
                          {
                            id: "CategoryMarginSingle",
                            path: ':categoryId',
                            element: <MarginFields />,
                            loader: MarginFields.Loader,
                            action: MarginFields.Action,
                          }
                        ]
                      },
                    ]
                  },
                ]
              }

            ]
          },
        ])} />
      </AuthProvider>
    </div >
  )
}

function ErrorBoundary() {
  let error = useRouteError();
  // if (error instanceof AuthenticationError) {
  //   return <Login onLogin={AuthenticationError.onLogin} />
  // }
  console.error(error);
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}


export default App;
