import { DownOutlined } from '@ant-design/icons';
import { Tree } from 'antd';
import { generatePath, Outlet, useLoaderData, useMatch, useNavigate, useParams } from 'react-router-dom';
import ApiHelper from '../../util/ApiHelper.js';

function CategoryMargins() {
  const { supplier } = useParams()
  const childRouteMatch = useMatch('/supplier/:supplier/margins/:categoryId')

  const { categories } = useLoaderData()
  const navigate = useNavigate()

  const onSelect = (keys, info) => {
    if (keys.length === 0) {
      navigate(generatePath('/supplier/:supplier/margins', {
        supplier,
      }))
    }
    else {
      navigate(generatePath('/supplier/:supplier/margins/:categoryId', {
        supplier,
        categoryId: keys[0],
      }))
    }
  }

  return (
    <div className="App">
      <div className='page-content'>
        <div className="sidebar--fixed">
          <Tree
            showLine
            switcherIcon={<DownOutlined />}
            onSelect={onSelect}
            selectedKeys={[childRouteMatch?.params.categoryId]}
            treeData={categories}
            fieldNames={{
              title: "name",
              key: "value",
              children: "children",
            }}
            defaultExpandAll
            defaultExpandParent
          />
        </div>
        <div className="main-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

CategoryMargins.Loader = async ({ params }) => {
  const { supplier } = params
  const categories = await ApiHelper.getSupplierCmsCategories(supplier)
  return { categories: categories.map((cat) => ({ ...cat, selectable: false })) }
}

export default CategoryMargins;