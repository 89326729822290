import React, { useEffect, useState, useMemo, useRef, useImperativeHandle } from "react"
import { SelectList } from "./SelectList"
import Styles from './Mapper.module.scss'
import { Empty,message } from "antd"
import { invertBy, remove, without, omitBy, isEqual, sortBy } from 'lodash'
import ApiHelper from "../util/ApiHelper.js";

const addMapping = (source, target, mappings) => {
    return mappings
}

const deleteMapping = (target, mappings) => {
    return mappings
}

const Mapper = (props, ref) => {
    const saveMappingsRef = useRef()
    useImperativeHandle(ref, () => {
        return {
            getChangedMappingsRef: () => getChangedMappings()
        }
    })
    const [selectedSourceItem, setSelectedSourceItem] = useState(null)
    const [selectedTargetItem, setSelectedTargetItem] = useState(null)
    const [enableTargetMapping, SetEnableTargetMapping] = useState(false)
    const [mappings, SetMappings] = useState({})
    const [targetItems, setTargetItems] = useState(props.targetItems)
    const [rootParams, setRootParams] = useState({supplier:props.supplier,specification:props.specificationType})
    
    const getChangedMappings = () => {
        //Find changed mappings
        return omitBy(mappings, (v, k) => {
          return isEqual(sortBy(v), sortBy(props.mappings[k]))
        })
    }

    const handleSourceItemClick = (event, item) => {
        if(!item || (selectedSourceItem && item.id === selectedSourceItem.id)){
            setSelectedSourceItem(null)
        }
        else{
            setSelectedSourceItem(item)
        }
    }

    const handleTargetItemClick = (event, item) => {
        if(!item || (selectedTargetItem && item.id === selectedTargetItem.id)){
            setSelectedTargetItem(null)
        }
        else{
            setSelectedTargetItem(item)
        }
    }

    const onApply = (e) => {
        e.stopPropagation()
        //If source is already linked, remove it
        let _mappings = {...mappings}
        _mappings[selectedSourceItem.id] = selectedTargetItem.id
        // let sourceIdExisting = Object.keys(_mappings).find(key => _mappings[key].includes(selectedTargetItem.id) )
        // if(sourceIdExisting){
        //     remove(_mappings[sourceIdExisting], selectedTargetItem.id)
        //     if (_mappings[sourceIdExisting].length === 0){
        //         delete _mappings[sourceIdExisting]
        //     }
        // }
        
        // if(!_mappings[selectedSourceItem.id]){
        //     _mappings[selectedSourceItem.id] = []
        // }
        // _mappings[selectedSourceItem.id].push(selectedTargetItem.id)
        //_mappings = Object.assign({}, _mappings, { [selectedSourceItem.id] : selectedTargetItem.id })
        
        SetMappings(_mappings)
        setSelectedTargetItem(null)
    }

    const deleteMapping = (event, sourceItemId, targetItemId, rootParam) => {
        console.log({sourceItemId, targetItemId})
        let _mappings = {...mappings}
        if(_mappings[sourceItemId] !== targetItemId){
           alert('Mapping not found')
        }
        if( rootParam.supplier && rootParam.specification){
            let data = { 
                        supplierCode:rootParam.supplier,
                        type:rootParam.specification,
                        source:sourceItemId,
                        target:targetItemId 
                    }
                    console.log(data)
            ApiHelper.removeMapping(rootParam.supplier,rootParam.specification,data)
                .then((res) => {
                    if (res) {
                        message.success(res.message)
                        delete _mappings[sourceItemId]
                        SetMappings(_mappings);
                    }
                    else {
                        message.error(res.message)
                    }
                })
                .catch((error) => {
                    message.error("An error occurred while deleting the mapped specification.");
                });
        }
    }

    const getMemoisedTargetMapping = useMemo(() => {
        let __targetMappings = {}
        for(let sourceId in mappings){
            __targetMappings[sourceId] = targetItems.find(item => mappings[sourceId] === item.id )
        }
        console.log({__targetMappings, mappings})
        return __targetMappings
    }, [mappings, props.sourceItems, targetItems])

    const getMemoisedSourceMapping = useMemo(() => {
        //invert object key,values and merge if value is array
        let __reverseMapings = invertBy(mappings)
        // for (let j in mappings){
        //     if(Array.isArray(mappings[j])){
        //         mappings[j].forEach(element => {
        //             __reverseMapings[element] = j
        //         });
        //     }
        //     else{
        //         __reverseMapings[mappings[j]] = j
        //     }
        // }
        
        //Get spec details from source list
        let __sourceMappings = {}
        for(let targetId in __reverseMapings){
            let sourceItem = props.sourceItems.filter(item => __reverseMapings[targetId].includes(item.id))
            __sourceMappings[targetId] = sourceItem
        }
        // console.log({__sourceMappings, mappings})
        return __sourceMappings
    }, [mappings, props.sourceItems, targetItems])


    useEffect(() => {
        //Check if source and target are selected
        SetEnableTargetMapping(selectedSourceItem && selectedTargetItem)    
    }, [selectedSourceItem, selectedTargetItem])
    
    useEffect(() => {
        setSelectedSourceItem(null)
        setSelectedTargetItem(null)
        SetMappings(props.mappings)
        // SetTargetMappings({})
        // SetSourceMappings({})
    }, [props.mappings])

    useEffect(() => {
        setRootParams({supplier:props.supplier,specification:props.specificationType})
        if( targetItems.length > 0 ){
            setTargetItems(targetItems);
        }else{
            setTargetItems(props.targetItems);
        }
        if(targetItems.length === 1){
            setSelectedTargetItem(targetItems[0])
        }
        if(props.sourceItems.length === 1){
            setSelectedSourceItem(props.sourceItems[0])
        }
    }, [mappings, targetItems, props.sourceItems])

    const changeTargetList = (items) => {
        setTargetItems(items)
    }

    return (
        <div className={Styles.Component}>
            <SelectList 
                id="PrintingOptionsSource" 
                title="Supplier"
                items={props.sourceItems} 
                selectedItem={selectedSourceItem}
                selectionChanged={handleSourceItemClick}
                mappings={getMemoisedTargetMapping}
                listHeight={props.listHeight}
                isSearchable
            />
            <SelectList 
                id="ColorsTarget" 
                title="CMS"
                items={targetItems} 
                selectedItem={selectedTargetItem}
                selectionChanged={handleTargetItemClick} 
                enableTargetMapping={enableTargetMapping}
                mappings={getMemoisedSourceMapping}
                isTarget={true}
                onApply={onApply}
                selectedSourceItem={selectedSourceItem}
                listHeight={props.listHeight}
                isSearchable
                specificationType={props.specificationType}
                onChangeTargetList={changeTargetList}
            />
            <div className={Styles.Component__details}>
                <div className={Styles['Component__details--wrapper']}>
                    {selectedTargetItem ?
                        <div className={Styles.SelectedTargetMappings}>
                            <h3>{selectedTargetItem.title.nl}</h3>
                            <ul className={Styles.MappingListChanges}>
                                {getMemoisedSourceMapping[selectedTargetItem.id]?getMemoisedSourceMapping[selectedTargetItem.id].map((sourceItem) => {
                                    return (
                                        <li key={sourceItem.id}>
                                            <span className="target">{sourceItem.title.nl}</span>
                                            <button onClick={(event) => deleteMapping(event, sourceItem.id, selectedTargetItem.id,rootParams)}>Delete</button>
                                        </li>
                                    )
                                }):<Empty description="Not yet mapped"/>}
                            </ul>
                        </div>
                    : ''}
                    <div className={Styles.changesWrapper}>
                        <h3>Unsaved Changes</h3>
                        <ul className={Styles.MappingListChanges}>
                        {Object.entries(getChangedMappings()).length > 0 ? Object.entries(getChangedMappings()).map((mapping) => {
                            const sourceItem = getMemoisedSourceMapping[mapping[1]]
                            const targetItem = getMemoisedTargetMapping[mapping[0]]
                            return (
                                <li key={mapping[0]}>
                                    <span className="source">{sourceItem[0]?sourceItem[0].title.nl:''}  <small>{sourceItem.length > 1 ? `(+${sourceItem.length - 1})` : ''}</small></span>
                                    <span className="target">{targetItem?targetItem.title.nl:''}</span>
                                    <button onClick={(event) => deleteMapping(event, sourceItem[0].id, targetItem.id)}>Delete</button>
                                </li>
                            )
                        }):<Empty description="No Changes Yet"/>}
                        </ul>
                    </div>
                </div>                   
            </div>
        </div>
    )
}

export default React.forwardRef(Mapper)